@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

$backgroundColor: white;
$textcolor: #000000;
$borderLeftColor: $primary;

.navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    background-color: $backgroundColor;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
    transition: all 0.5s ease;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    // margin-left: -80px;

    .burger {
        display: flex;
        justify-content: flex-end;
        img, .burger-icon {
            font-size: 3em;
            color: $primary;
            opacity: .5;
            margin: 10px;
            cursor: pointer;
            &:hover {
                opacity: 1;
                transition: .2s ease;
            }
        }
    }

    .navbar__list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .navbar__li-box {
            height: 65px;
            transition: height 0.3s ease;
            margin-top: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            

            &:first-child {
                margin-top: 25px;
            }

            &:hover {
                border-left: 7px solid $borderLeftColor;
                cursor: pointer;
            }

            .navbar__action, a {
                height:65px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column; // Horizontal by default
                width: 100%;
                text-decoration: none;
                text-align: center;

                .navbar__icon {
                    font-size: 2em;
                    // margin-right: 10px; // Margin for alignment
                    color: grey;
                    transition: margin-right 0.3s ease;
                }

                .navbar__li {
                    font-size: 16px;
                    color: $textcolor;
                    font-family: 'Lato';
                    font-weight: 400;
                    display: block; // Show by default
                    transition: opacity 0.3s ease, transform 0.3s ease;
                    opacity: 0; // Initially hidden
                    transform: translateX(-10px); // Move left
                }
            }
        }
    }

    &.expanded {
        .navbar__li-box {
            height: 90px;
            transition: height 0.3s ease;

        }

        .navbar__action, .navbar__li-box a {
            flex-direction: column; // Stack vertically

            .navbar__icon {
                margin-right: 0; // Reset margin
            }

            .navbar__li {
                opacity: 1!important; // Show text
                transform: translateX(0); // Reset position
                transition: opacity 0.3s ease, transform 0.3s ease;

            }
        }
    }

    .navbar__list-bottom-container {
        margin-top: auto;
        margin-bottom: 15px;
    }

    .navbar__list-bottom {
        margin-bottom: 20px;
    }

    .navbar__action {
        background: none;
        border: none;
        height: 43px;
        flex-direction: column; // Ensure vertical stacking
    }

    * {
        margin: 0;
        padding: 0;
    }
}
