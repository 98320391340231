/* =====================================
   CSS RESET / NORMALIZE
===================================== */
// Custom mixins using Bootstrap's breakpoint mixins
// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Mixin for each breakpoint
@mixin media-xs {
    @media (max-width: $breakpoint-sm - 1) {
        @content;
    }
}

@mixin media-sm {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
        @content;
    }
}

@mixin media-md {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
        @content;
    }
}

@mixin media-lg {
    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1) {
        @content;
    }
}

@mixin media-xl {
    @media (min-width: $breakpoint-xl) {
        @content;
    }
}



/* =====================================
   GLOBAL STYLES
===================================== */
/* Body, typography, and other global styles */
// Override default colors
$primary: #5b3acc!important; // Your new primary color
$success: #28a745; // Your new success color

body {
    background: #ffffff;

}

.app {
    position: relative;
    // padding-left: 80px;

}

#tsparticles {

}



:root {
    --amplify-components-button-primary-background-color: #5b3acc!important;

}




////Shadows
///
///  
@mixin shaddow-style($n: 8px 8px 8px 8px) {
    box-shadow: 1px 1px 35px rgba(198, 211, 255, .28) !important;
    border: 1.2px solid rgba(62, 52, 200, .1) !important;
    border-radius: $n !important;

}


/* =====================================
   VARIABLES
===================================== */
/* Definitions for CSS variables like colors, fonts, etc. */



.light {
    color: lightgray;
}

/* =====================================
   GRID AND LAYOUT
===================================== */
/* Rules for layout grids, flexbox settings, etc. */

/* =====================================
   HEADERS
===================================== */
/* Styling for the site's header and navigation */
.header-logged-in {
    padding: 50px 0 25px;
    /* Styles when the user is logged in */
#liiaLogo {
    position:fixed;
    right: -10px;
    bottom:55px;
    transform: rotate(-90deg);
    max-height: 100px;
}

}

.header-logged-out {
    /* Styles when the user is not logged in */
    background-color: #007239;
}




/* =====================================
   FOOTERS
===================================== */
/* Styles specific to the footer section */
/* nav */
.footer {
    background-color: #312a10;
    color: #fff;
    padding: 0 0 5em;
    line-height: 100%;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;
    max-height: 800px
}

.footer .nav {
    margin-top: 0
}

.footer .legal {
    max-width: 700px;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 110%;
    margin-top: 3rem
}




/* =====================================
   TYPOGRAPHY
===================================== */
/* Detailed typography settings: headings, paragraphs, links */

/* =====================================
   BUTTONS AND FORMS
===================================== */
/* Styles for buttons, input fields, labels, etc. */

///// Buttons

@mixin btn {
    padding: 15px;
    background-color: $primary;
    // position: relative;
    min-height: 50px;
}

.btn {
    @include btn;

}



.btn-secondary {
    background-color: rgba(255, 255, 255, 0.5)!important;
    color: $primary;
    margin-right: 1rem;
}



/// Forms

.form-check {
    margin-bottom: 25px!important;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: white;
    @include shaddow-style;

    &:hover {
        border-color: $primary;
        transition: border-color 0.3s ease;
    }
}

.form-check label {
    min-width: 100%;
}

.form-check.selected {
    transform: scale(1.04);
    /* Slightly increase the size */
    background-color: #83ffc14f;
    /* Change background color */
    border-color: #007239;
    transition: transform 0.3s ease, background-color 0.3s ease;
    /* Smooth transition for the transform and background color */
}

/// Form Inputs

input[type="radio"] {
    display: none;
}


/* =====================================
   NAVIGATION
===================================== */
/* Specific styles for navigational elements like menus, breadcrumbs */

@import '../components/NavbarVerticle/CustomNav.scss';




/* =====================================
   MAIN CONTENT AREA
===================================== */
/* Styles specific to the main content sections */
.onboarding {
    max-width: 540px;
& .accordion {
    margin-top:2rem;
    margin-bottom:2rem;
}

}



.questionContainer {

    min-height: 70vh;

}

.particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }




/* =====================================
   SIDEBARS AND WIDGETS
===================================== */
/* Styles for sidebars, widgets, or any additional components */

// CARDS
.card {
    @include shaddow-style(15px 15px 15px 15px);
    transition: box-shadow 0.3s ease; // Add this 
    .card-title {
        color: white;
    }
    .card-text {
        display: none;
        opacity: 0;
    }


    &::before {
        @include shaddow-style(15px 15px 15px 15px);
        content: '';
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../public/images/assessment_bg/test.webp');
        background-size: cover;
        background-position: top;
        opacity: 1; /* Set your desired opacity here */
        z-index: -1;
        background-repeat: no-repeat;

   
    
    }

    &:hover::before {
        background-image: linear-gradient(
            to bottom, 
            rgba(255, 255, 255, 0) 0, 
            rgba(255, 255, 255, 0.87) 75px, 
            rgba(255, 255, 255, 0.93) 100%
        ), url('../../public/images/assessment_bg/test.webp');
        border: $primary;
    }
    

    &:hover {
        box-shadow: 1px 1px 35px rgba(0, 92, 0, 0.28) !important;
        .card-title {
            color: $primary;
        }
        .card-text {
            display:block;
            opacity: 1;
        }

        &::before {
            // opacity: .1;
        }
    }
}


.card-body {

& .badge {
    margin:5px;
    margin-bottom: 15px;
    &:first {
        margin-left:50px;
    }
}

& .card-title {
    margin-top:1rem;
}

& .btn {
margin-top: 1.5rem;
&:hover{
    @include shaddow-style()
}

}

}

.badge-filters label {
    margin-bottom: 25px!important;
    margin-right: .67rem;
    font-size: .9rem;
    padding-left: .4rem;
    padding-right: .4rem;
}

.badge-filters input[type="checkbox"] {
    display: none;
}

.badge-filters input[type="checkbox"]:checked + label {
    background-color: $primary;
    color:white;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.badge-filters label:hover {
    border-color: $primary;
    transition: border-color 0.3s ease;
}


// OFFCANVAS

.offcanvas {
    max-width: 200px !important;;
}

.filters {
    position: fixed;
    top: 2rem;
    right: 2rem;
}

/* 
///// Authentication
background-customizable

banner-customizable

errorMessage-customizable

idpButton-customizable

idpButton-customizable:hover

idpDescription-customizable

inputField-customizable

inputField-customizable:focus

label-customizable

legalText-customizable



passwordCheck-valid-customizable

passwordCheck-notValid-customizable

redirect-customizable

socialButton-customizable

submitButton-customizable

submitButton-customizable:hover

textDescription-

/// */

.amplify-image {
    margin-top: 80px;
    max-height: 100px;
}


div[data-amplify-router],
.amplify-tabs {
    @include shaddow-style
}



.amplify-tabs__item {
    border-color: $primary;
    &:hover {
        background-color: $primary;
        color: white !important;
        opacity: .5;
    }
}

.amplify-tabs__item--active {
    color: primary!important;
}

#signIn-tab {
    &:hover {
        @include shaddow-style(0px 0px 0px 8px)
    }
}

#signUp-tab {
    &:hover {
        @include shaddow-style(0px 0px 8px 0px)
    }
}

[data-amplify-authenticator] {
    // background-color: white !important;
}

///// Carousel


#questionsCarousel {

    // background-color: rgb(255, 255, 255);
    padding: 55px;
    max-width: 660px;
    border-radius: 15px;

    @include media-xs {
        padding: 15px 15px 55px;
    }
}

#questionsCarousel h3 {
    margin-bottom: 50px;
}

#questionsCarousel .carousel-inner {
    overflow: visible !important;
}

#questionsCarousel h5 {

    margin-bottom: 35px;
}

.carousel-control-next,
#submitButton {
    @include btn;
    position: relative;

    &:active {
        opacity: .5!important;
    }

    &:not(:focus):hover {
        opacity: 1!important;
    }

}

.ready {
    opacity: .8!important;
}


.carousel-control-prev {
    background-color: lightgrey!important;
    cursor: pointer;
}

.indicatorDiv {
    margin-top: 3rem;
}

.carousel-indicators {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    // margin-bottom: 10px;
    // position: relative;
    // bottom: -50px;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #5b3acc;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;

    &.active {
        opacity: 1;
        height: 9px !important;
    }

    &:hover {
        opacity: 1;
        height: 9px !important;
    }

}

#submitButton {
    background-color: #28a746a5!important;
    border-color: #007239!important;

    &:hover {
        background-color: $success!important;
    }
}


.carousel-indicators li.answered {
    background-color: rgb(0, 195, 0);
    height: 6px;
}


//Carousel Navigation

.question-nav {
    position:relative;
    display: flex;
    margin:5px;
    // padding:5px;

}

/* =====================================
   MEDIA QUERIES
===================================== */
/* Responsive design breakpoints */



/* =====================================
   ANIMATIONS AND TRANSITIONS
===================================== */
/* Keyframes and rules for animations and transitions */
@keyframes heightChange {
    from {
        height: 3px;
    }

    to {
        height: 12px;
    }

    from {
        height: 12px;
    }

    to {
        height: 6px;
    }
}

.animate-height {
    animation: heightChange 1.2s forwards;
    /* Adjust duration as needed */
}
  
  .fade-in #tsparticles {
    animation: fadeIn .3s ease-in-out forwards;
  }
  
  .fade-out #tsparticles {
    animation: fadeOut .3s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  


// Card Animations
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card {
    animation: fadeInUp 0.5s ease-out forwards;
    /* Initial state of the card before animation starts */
    opacity: 0;
    transform: translateY(20px);
}




/* =====================================
   UTILITY CLASSES
===================================== */
/* General utility/helper classes */

// LOGIN AND AUTHENTICATOR
/* Custom CSS class for Authenticator */
.authenticator-container {
    z-index: 10; /* higher than ParticleBg */
    position: relative; /* or 'absolute' if needed */
}



/* =====================================
   PRINT STYLES
===================================== */
/* CSS rules for print layout */

/* =====================================
   VENDOR PREFIXES / CROSS-BROWSER COMPATIBILITY
===================================== */
/* Vendor-specific prefixes and cross-browser compatibility styles */

/* =====================================
   COMMENTS AND DOCUMENTATION
===================================== */
/* Detailed comments for clarity and future reference */

/* OVERRIDES */

.disabled {
    opacity: 0.5!important;
    cursor: auto;

    &:hover {
        opacity: 0.5!important;
        cursor: auto;
    }
}

.sd-element__title span  {
    color: $primary!important;
    font-size: 1.5rem!important;
    line-height: 1.67rem!important;
}

.sd-container-modern {
    // background-color: rgba(151, 0, 0, 0.297)!important;
}

// .sd_main {background-color:green!important;}

// .sd-root-modern__wrapper {background-color: rgba(0, 0, 0, 0.206);}

// :root {
//     --sjs-general-backcolor: rgba(204, 0, 0, 0.413)!important; /* Replace #yourColor with your desired color */
//     --sjs-primary-backcolor: rgba(0, 128, 0, 0.638)!important;
// }

.sd-html quote {
    font-size:1.5rem;
    line-height:167%;
    
    & span{
        font-size:1.5rem;
        line-height:167%;
        font-weight:800;
    }
}